import React from "react";
import styled from "styled-components";
import { format } from "date-fns";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 3rem;
  padding: 1rem;

  border-bottom: 1px solid #d5dce2;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

const Title = styled.h1`
  font-size: 1rem;
  margin-bottom: 0;
`;

const DatePicker = styled.input`
  border: none;
`;

const Header: React.FC<{
  signedIn: boolean;
  date: Date;
  onDateChange: (date: Date) => void;
}> = ({ signedIn, date, onDateChange }) => (
  <Container>
    <Title>TimeBlocks</Title>

    <DatePicker
      type="date"
      value={format(date, 'YYYY-MM-DD')}
      onChange={e => onDateChange(new Date(e.target.value))}
    />

    {signedIn ? (
      <Button onClick={() => this.gdrive.signOut()}>Sign out</Button>
    ) : (
      <Button onClick={() => this.gdrive.signIn()}>Sign in</Button>
    )}
  </Container>
);

export default Header;
