const API_KEY = "AIzaSyCzuraYXBpK7Loh-RY2BXFjTCquubCpkso";
const CLIENT_ID =
  "731201843452-kent8b96i6p1adqhe0f65isukicc4e2m.apps.googleusercontent.com";

// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = "https://www.googleapis.com/auth/drive.appdata";

const BOUNDARY =
  "FHe3OZqgCNuPAHeDO6yCvj5Aaq8G42sYTABL5QcVWaIM1dMOulVCNNKyjFT98G42";

class GoogleDrive {
  constructor(
    onSignIn: () => void,
    onSignOut: () => void,
    onError: (e: Error) => void
  ) {
    gapi.load("client:auth2", () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES
        })
        .then(function() {
          // Listen for sign-in state changes.
          gapi.auth2
            .getAuthInstance()
            .isSignedIn.listen(isSignedIn =>
              isSignedIn ? onSignIn() : onSignOut()
            );

          // Handle the initial sign-in state.
          if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
            onSignIn();
          }
        }, onError);
    });
  }

  signIn() {
    gapi.auth2.getAuthInstance().signIn();
  }

  signOut() {
    gapi.auth2.getAuthInstance().signOut();
  }

  async getFileList() {
    const response = await gapi.client.drive.files.list({
      spaces: "appDataFolder",
      fields: "nextPageToken, files(id, name)",
      pageSize: 100
    });

    return response.result.files;
  }

  async findFile(name: string) {
    const listResponse = await gapi.client.drive.files.list({
      spaces: "appDataFolder",
      fields: "files(id, name)",
      q: `name = '${name}'`,
      pageSize: 1
    });
    const foundFiles = listResponse.result.files;
    if (foundFiles.length !== 1) {
      return undefined;
    }
    return foundFiles[0];
  }

  async getFile(name: string) {
    const file = await this.findFile(name);

    if (!file) {
      throw new Error("Unknown file");
    }

    const response = await gapi.client.drive.files.get({
      fileId: file.id,
      alt: 'media',
    });

    return response.body;
  }

  async setFile(name: string, content: string) {
    const file = await this.findFile(name);
    const baseUrl = "https://www.googleapis.com/upload/drive/v3/files";

    let response;
    if (file) {
      response = await gapi.client.request({
        path: `${baseUrl}/${file.id}`,
        method: "PATCH",
        headers: {
          "Content-Type": `text/plain`
        },
        params: {
          uploadType: "media"
        },
        body: content
      });
    } else {
      const metaData = {
        name,
        parents: ["appDataFolder"]
      };

      const body = `--${BOUNDARY}
Content-Type: application/json; charset=UTF-8

${JSON.stringify(metaData)}

--${BOUNDARY}
Content-Type: text/plain

${content}
--${BOUNDARY}--`;

      response = await gapi.client.request({
        path: baseUrl,
        method: "POST",
        headers: {
          "Content-Type": `multipart/related; boundary=${BOUNDARY}`
        },
        params: {
          uploadType: "multipart"
        },
        body
      });
    }

    console.log("Create response", response);

    return response.result;
  }
}

export default GoogleDrive;
