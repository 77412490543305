import React, { useState, useEffect } from "react";
import styled from "styled-components";
import randomColor from "randomcolor";
import Push from 'push.js';
import { addMinutes, format } from "date-fns";

interface Block {
  id: string;
  text: string;
  plannedAlert: boolean;
}

const Container = styled.div`
  width: 100%;
  height: 100%;

  overflow-y: auto;
`;

const Notification = styled.div<{ size: number, planned: boolean }>`
  width: 2rem;
  height: ${p => p.size * 2}rem;
  line-height: ${p => p.size * 2}rem;
  text-align: center;

  opacity: ${p => p.planned ? '1' : '0.2'};
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const Time = styled.div<{ size: number }>`
  width: 3rem;
  height: ${p => p.size * 2}rem;
  line-height: ${p => p.size * 2}rem;
  text-align: center;
`;

const Entry = styled.div`
  display: flex;
`;

const Input = styled.input<{ color: string; size: number }>`
  width: 100%;
  height: ${p => p.size * 2}rem;
  resize: none;
  border: none;
  border-left: solid 0.5rem #${p => p.color};

  font-family: "Courier New", Courier, monospace;
`;

const randomId = (seed?: string) => randomColor({ seed }).replace("#", "");

const blockSizeFromText = (text: string) => {
  const match = text.match(/.*- (\d)b/);
  return match ? parseInt(match[1]) : 1;
};

const focusEntry = (id: string) => {
  const newInput = document.querySelector(
    `[data-id="${id}"] input`
  ) as HTMLInputElement;
  newInput && newInput.focus();
};

const Editor: React.FC<{
  onUpdate: (content: string) => void;
  content: string;
}> = ({ onUpdate, content }) => {
  const [blocks, setBlocks] = useState<Block[]>([
    {
      id: "000000",
      text: "",
      plannedAlert: false
    }
  ]);
  useEffect(() => {
    if (content) {
      const newBlocks = content.split("\n").map((text, index) =>
        blocks[index] && blocks[index].text === text
          ? blocks[index]
          : {
              id: randomId(text || undefined),
              text,
              plannedAlert: false
            }
      );
      setBlocks(newBlocks);
    }
  }, [content]);

  const createEmptyBlock = (position?: number) => {
    const id = randomId();

    const newBlocks: Block[] = [...blocks];
    newBlocks.splice(position || blocks.length, 0, {
      id,
      text: "",
      plannedAlert: false
    });

    setBlocks(newBlocks);

    return id;
  };

  const handleUpdate = (id: string, value: string) => {
    const oldBlockIndex = blocks.findIndex(b => b.id === id);
    const oldBlock = blocks[oldBlockIndex];

    const newBlocks: Block[] = [...blocks];
    newBlocks.splice(oldBlockIndex, 1, { ...oldBlock, text: value });

    setBlocks(newBlocks);
    onUpdate(newBlocks.map(b => b.text).join("\n"));
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    var code = event.keyCode || event.which;
    // Enter key
    if (code === 13) {
      const index = blocks.findIndex(b => b.id == id);
      const newId = createEmptyBlock(index + 1);
      setTimeout(() => focusEntry(newId));
    }
    // Backspace and delete
    if (code === 8 || code === 46) {
      const isDelete = code === 46;

      const value = event.currentTarget.value;
      if (value.length === 0 && Object.keys(blocks).length !== 1) {
        setBlocks(blocks.filter(b => b.id !== id));
        const focusedIndex =
          blocks.findIndex(b => b.id === id) + (isDelete ? +1 : -1);
        setTimeout(() => blocks[focusedIndex] && focusEntry(blocks[focusedIndex].id));
      }
    }
    // Up and down arrows
    if (code === 38 || code === 40) {
      const isDown = code === 40;
      const focusedIndex =
        blocks.findIndex(b => b.id === id) + (isDown ? +1 : -1);
      setTimeout(() => blocks[focusedIndex] && focusEntry(blocks[focusedIndex].id));
    }
  };

  const handlePlannedAlert = (block: Block) => {
    const oldBlockIndex = blocks.findIndex(b => b.id === block.id);

    const newBlocks: Block[] = [...blocks];
    newBlocks.splice(oldBlockIndex, 1, { ...block, plannedAlert: true });
    setBlocks(newBlocks);

    const minutes = 15 * blockSizeFromText(block.text);
    const endDate = addMinutes(new Date(), minutes);
    setTimeout(() => {
      Push.create(`${format(endDate, 'H:m')} End of: ${block.text}`, { requireInteraction: true });
      const oldBlockIndex = blocks.findIndex(b => b.id === block.id);

      const newBlocks: Block[] = [...blocks];
      newBlocks.splice(oldBlockIndex, 1, { ...block, plannedAlert: false });
      setBlocks(newBlocks);
    }, 1000 * 60 * minutes);
  };

  return (
    <Container>
      {blocks.map(b => {
        const blockSize = blockSizeFromText(b.text);
        const time = `${blockSize * 15}m`;

        return (
          <Entry key={b.id} data-id={b.id}>
            <Notification size={blockSize} planned={b.plannedAlert} onClick={() => handlePlannedAlert(b)}>⏱</Notification>
            <Time size={blockSize}>{time}</Time>
            <Input
              size={blockSize}
              onBlur={e => handleUpdate(b.id, e.target.value)}
              defaultValue={b.text}
              color={b.id}
              onKeyDown={event => handleKeyDown(event, b.id)}
            />
          </Entry>
        );
      })}
    </Container>
  );
};

export default React.memo(Editor);
