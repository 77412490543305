import React from "react";
import styled from "styled-components";
import { format } from "date-fns";

import GoogleDrive from "../GoogleDrive";
import Header from "./Header";
import Editor from "./Editor";
import { debounce } from "../debounce";

const Dash = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

class App extends React.Component<
  {},
  {
    signedIn: boolean;
    date: Date;
    content: string;
    error: string;
  }
> {
  private gdrive: GoogleDrive;

  state = {
    signedIn: false,
    date: new Date(),
    content: "",
    error: ""
  };

  private debouncedSetFile = debounce((name: string, content: string) => {
    this.gdrive.setFile(name, content);
  }, 1000 * 5);

  public componentWillMount() {
    this.gdrive = new GoogleDrive(
      this.onSingIn.bind(this),
      this.onSignOut.bind(this),
      this.onError.bind(this)
    );
  }

  private async onSingIn() {
    this.setState({ signedIn: true });

    const content = await this.gdrive.getFile(
      format(this.state.date, "YYYY-MM-DD")
    );

    this.setState({ content });
  }

  private onSignOut() {
    this.setState({ signedIn: false });
  }

  private onError(e: Error) {
    console.error(e);
  }

  public render() {
    const handleDateChange = async (date: Date) => {
      this.setState({ date, content: '' });

      const content = await this.gdrive.getFile(
        format(date, "YYYY-MM-DD")
      );
  
      this.setState({ content });
    };

    const handleContentChange = (content: string) => {
      this.setState({ content });

      this.debouncedSetFile(format(this.state.date, "YYYY-MM-DD"), content);
    };

    return (
      <Dash>
        <Header
          signedIn={this.state.signedIn}
          date={this.state.date}
          onDateChange={handleDateChange}
        />

        <Editor onUpdate={handleContentChange} content={this.state.content} />
      </Dash>
    );
  }
}

export default App;
