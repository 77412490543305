import reboot from 'styled-reboot';
import { createGlobalStyle } from 'styled-components';

const rebootCss = reboot();

const GlobalStyle = createGlobalStyle`
  ${rebootCss}
  /* other styles */
`

export default GlobalStyle;
